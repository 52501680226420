import * as React from 'react';
import Default from '../../layouts/default';
import SEO from 'components/seo';
import {
  ABOUT_TEAM_URL,
  ABOUT_URL,
  API_DOCS_CREATE_APP_URL,
  ATLASSIAN_INTEGRATION,
  AWS_INTEGRATION,
  BLOG_URL,
  BREATHE_LIFE_URL,
  CUSTOMERS_URL,
  DEMO_PAGE_URL,
  DOCS_AUTHENTICATED_SCANNING,
  DOCS_URL,
  DOCS_WEB_APP,
  DOCS_WORKFLOW_INTEGRATIONS,
  GITHUB_INTEGRATION,
  JOBS_URL,
  LOGIN_URL,
  CHANGEORG_URL,
  ONE_MEDICAL_URL,
  PLANETLY_URL,
  PRESS_URL,
  PRICING_URL,
  PRODUCT_URL,
  SECURITY_URL,
  SNYK_INTEGRATION,
  SOLUTION_API_SECURITY_URL,
  SOLUTION_DEVELOPER_CENTRIC_URL,
  SOLUTION_DEVSECOPS_URL,
  SOLUTION_GETTING_STARTED_URL,
  SOLUTION_GRAPHQL_SECURITY_URL,
  SOLUTION_GRPC_TESTING,
  SOLUTION_MODERN_DAST_URL,
  SOLUTION_OWASP_TOP_URL,
  ZAP_FUND_URL,
} from 'core/links';

const Sitemap: React.FC = () => {
  const seo = {
    title: 'StackHawk Site Map',
    description:
      ' Use the sitemap to find your way around StackHawk.com.  Learn about our products, solutions, integrations and more.',
    canonicalUrl: '',
    noindex: false,
    nofollow: false,
  };

  return (
    <Default>
      <SEO data={seo} />
      <div className="mt-12 container p-4">
        <h1 className="p-4">Site Map</h1>
        <div className="d-flex flex-xl-row flex-column p-4">
          <div className="py-6 pr-8">
            <h2>PRODUCT</h2>
            <ul className="list-unstyled">
              <a href={PRODUCT_URL}>
                <li>Overview</li>
              </a>
              <a href={DEMO_PAGE_URL}>
                <li>Watch a Demo</li>
              </a>
              <a href={PRICING_URL}>
                <li>Pricing</li>
              </a>
              <a href={DOCS_URL} target="_blank" rel="noreferrer">
                <li>Get Started</li>
              </a>
              <a href={LOGIN_URL} target="_blank" rel="noreferrer">
                <li>Sign In</li>
              </a>
            </ul>
          </div>
          <div className="py-6 pr-8">
            <h2>SOLUTIONS</h2>
            <ul className="list-unstyled">
              <a href={SOLUTION_MODERN_DAST_URL}>
                <li>Modern DAST</li>
              </a>
              <a href={SOLUTION_DEVSECOPS_URL}>
                <li>DevOps</li>
              </a>
              <a href={SOLUTION_DEVELOPER_CENTRIC_URL}>
                <li>Developer-First AppSec</li>
              </a>
              <a href={SOLUTION_GRAPHQL_SECURITY_URL}>
                <li>GraphQL Security Testing</li>
              </a>
              <a href={SOLUTION_GETTING_STARTED_URL}>
                <li>Getting Started with APPSec</li>
              </a>
              <a href={SOLUTION_API_SECURITY_URL}>
                <li>API Security Testing</li>
              </a>
              <a href={SOLUTION_OWASP_TOP_URL}>
                <li>OWASP Top 10</li>
              </a>
              <a href={SOLUTION_GRPC_TESTING}>
                <li>API Security Testing</li>
              </a>
            </ul>
          </div>
          <div className="py-6 pr-8">
            <h2>INTEGRATIONS</h2>
            <ul className="list-unstyled">
              <a href={SNYK_INTEGRATION}>
                <li>Snyk</li>
              </a>
              <a href={GITHUB_INTEGRATION}>
                <li>GitHub</li>
              </a>
              <a href={AWS_INTEGRATION}>
                <li>AWS</li>
              </a>
              <a href={ATLASSIAN_INTEGRATION}>
                <li>Atlassian</li>
              </a>
            </ul>
          </div>
          <div className="py-6 pr-8">
            <h2>CUSTOMERS</h2>
            <ul className="list-unstyled">
              <a href={ONE_MEDICAL_URL}>
                <li>One Medical</li>
              </a>
              <a href={BREATHE_LIFE_URL}>
                <li>Breathe Life</li>
              </a>
              <a href={PLANETLY_URL}>
                <li>Planetly</li>
              </a>
              <a href={CHANGEORG_URL}>
                <li>Change.org</li>
              </a>
            </ul>
          </div>
        </div>
        <div className="d-flex p-4 flex-xl-row flex-column">
          <div className="py-6 pr-8">
            <h2>DOCS</h2>
            <ul className="list-unstyled">
              <a href={DOCS_URL} target="_blank" rel="noreferrer">
                <li>Getting Started</li>
              </a>
              <a href={DOCS_WORKFLOW_INTEGRATIONS} target="_blank" rel="noreferrer">
                <li>Integrations</li>
              </a>
              <a href={DOCS_AUTHENTICATED_SCANNING} target="_blank" rel="noreferrer">
                <li>Authenticated Scanning</li>
              </a>
              <a href={API_DOCS_CREATE_APP_URL} target="_blank" rel="noreferrer">
                <li>StackHawk API</li>
              </a>
              <a href={DOCS_WEB_APP} target="_blank" rel="noreferrer">
                <li>StackHawk Platform</li>
              </a>
            </ul>
          </div>
          <div className="py-6 pr-8">
            <h2>RESOURCES</h2>
            <ul className="list-unstyled">
              <a href={ABOUT_URL}>
                <li>About</li>
              </a>
              <a href={BLOG_URL}>
                <li>Blog</li>
              </a>
              <a href={PRESS_URL}>
                <li>Press Room</li>
              </a>
              <a href={DEMO_PAGE_URL}>
                <li>Watch a Demo</li>
              </a>
              <a href={ZAP_FUND_URL}>
                <li>ZAP Fund</li>
              </a>
              <a href={DOCS_URL} target="_blank" rel="noreferrer">
                <li>Documentation</li>
              </a>
            </ul>
          </div>
          <div className="py-6 pr-8">
            <h2>COMPANY</h2>
            <ul className="list-unstyled">
              <a href={CUSTOMERS_URL}>
                <li>Customers</li>
              </a>
              <a href={ABOUT_TEAM_URL}>
                <li>Team</li>
              </a>
              <a href={JOBS_URL}>
                <li>Jobs</li>
              </a>
              <a href={SECURITY_URL}>
                <li>Security</li>
              </a>
            </ul>
          </div>
        </div>
      </div>
    </Default>
  );
};

export default Sitemap;
